<template>
  <section class="branch">
    <img :src="image" alt="" class="branch__img" />
    <div class="branch__filter"></div>
    <div class="branch__inner">{{title}}</div>
  </section>
</template>

<script>
export default {
  name: "branch-box",
  props: ["image",'title'],
};
</script>

<style scoped>
.branch {
  width: 92%;
  height: 300px;
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  z-index: 4;
  margin-bottom: 15px;
}
.branch__img {
  z-index: 1;
  width: 100%;
  min-height: 300px;
  filter: saturate(0.15);
}
.branch__filter {
  z-index: 3;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.3) 40%,
    rgba(0, 0, 0, 0.8)
  );
}
.branch__inner {
  z-index: 3;
  position: absolute;
  bottom: 15px;
  left: 0;
  right: 0;
  margin: 0 auto;
  color: #fff;
  text-align: center;
}
</style>