<template>
  <div class="store">
    <svg
      viewBox="0 0 800 512"
      data-v-21526c1a=""
      style="
        fill-rule: evenodd;
        clip-rule: evenodd;
        width: 48px;
        height: 48px;
        fill: #c69d4e;
        margin-bottom: 10px;
      "
    >
      <path
        d="M652.28 209.19l0 288.63c-0.17,7.92 -6.67,14.18 -14.57,14.18l-476.07 0c-0.1,0 -0.21,0 -0.32,-0.01 -7.64,-0.19 -13.76,-6.57 -13.56,-14.21l0 -288.59 -2.02 0c-21.66,0 -39.11,-18 -39.11,-39.54l0 -52.03c-0.02,-0.12 -0.04,-0.24 -0.04,-0.37 0.01,-0.4 -0.01,-0.79 0.03,-1.19 0.01,-0.19 0.03,-0.37 0.06,-0.55 0.03,-0.23 0.06,-0.46 0.1,-0.69 0.04,-0.2 0.07,-0.4 0.12,-0.58 0.04,-0.19 0.08,-0.36 0.13,-0.54 0.14,-0.52 0.3,-1.02 0.48,-1.49 0.02,-0.05 0.03,-0.09 0.05,-0.15l0.03 -0.08c0.11,-0.25 0.22,-0.5 0.34,-0.75l0.05 -0.11c0.04,-0.06 0.06,-0.12 0.09,-0.18 0.13,-0.26 0.27,-0.51 0.43,-0.78l0.03 -0.05c0.03,-0.06 0.07,-0.11 0.1,-0.17 0.17,-0.28 0.35,-0.54 0.53,-0.81l64.49 -92.39c7.33,-10.49 19.33,-16.74 32.13,-16.74l398.33 0c13.85,0 26.69,7.32 33.73,19.24l53.66 90.83c0.03,0.05 0.04,0.08 0.06,0.11 0.6,1.04 1.06,2.14 1.39,3.3 0.02,0.09 0.04,0.19 0.06,0.26 0.12,0.49 0.22,0.99 0.28,1.49 0.02,0.1 0.02,0.2 0.03,0.28l0.01 0.11c0.06,0.56 0.09,1.13 0.07,1.68l0 52.35c0,21.54 -17.44,39.54 -39.1,39.54l-2.02 0zm-504.12 -105.76l506.09 0 -40.96 -69.51c-1.92,-3.26 -5.39,-5.35 -9.17,-5.41l-398.3 0c-3.53,0.06 -6.78,1.84 -8.79,4.73l-48.87 70.19zm307.39 380.06l0 -165.89 -111.06 0 0 165.89 111.06 0zm168.22 0l0 -274.3 -447.5 0 0 274.3 139.72 0 -0.01 -180.09c-0.08,-7.82 6.19,-14.21 14.02,-14.3l139.36 0c8,0 14.47,6.39 14.7,14.38l0 180.01 139.71 0zm-342.13 -189.41c7.87,0 14.25,6.38 14.25,14.26l0 73.52c0,7.87 -6.38,14.25 -14.25,14.25l-78.5 0c-7.87,0 -14.26,-6.38 -14.26,-14.25l0 -73.52c0,-7.88 6.39,-14.26 14.26,-14.26l78.5 0zm314.02 0c7.87,0 14.25,6.38 14.25,14.26l0 73.52c0,7.87 -6.38,14.25 -14.25,14.25l-78.5 0c-7.87,0 -14.26,-6.38 -14.26,-14.25l0 -73.52c0,-7.88 6.38,-14.26 14.26,-14.26l78.5 0zm-328.27 73.52l0 -45.01 -50 0 0 45.01 50 0zm314.02 0l0 -45.01 -50 0 0 45.01 50 0zm83.49 -235.66l-529.75 0 0 37.71c0,5.93 4.63,10.88 10.57,11.04l508.52 -0.01c5.98,-0.16 10.66,-5.07 10.66,-11.03l0 -37.71z"
        data-v-21526c1a=""
      ></path>
    </svg>
    <p class="store__text">مشاهده فروشگاه</p>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang='scss'>
.store {
  width: 96%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #000;
  padding: 20px 0 30px;
  margin-top: auto;
  cursor: pointer;
  border-radius: 20px;
}
.store__text {
  color: #fff;
  font-size: 14px;
}
</style>