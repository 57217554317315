import http from '../UserService'

export const getStoreData = (id) => {
    return http.get(`user/wallet/${id}/`)
}

export const getCashiersData = (id) => {
    return http.get(`user/pay/${id}/`)
}

export const postPayWithCreditDetails = (id, data, header) => {
    return http.post(`user/pay/${id}/`, data, header)
}

export const getStores = (url) => http.get(url)