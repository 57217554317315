<template>
  <div class="resolved" v-if="!isLoading">
    <Navbar :text="navbarText" />
    <section class="events">
      <p v-if="events.length == 0" class="empty-events">
        هیچ رویداد جدیدی وجود ندارد
      </p>
      <Events
        v-for="event in events"
        :key="event.id"
        :type="event.type"
        :store="event.store"
        :time="event.created_at"
        :relation="event.relation_id"
        @click="handleNavigateEvents(event)"
      />
    </section>
    <Observer
      @intersect="intersect"
      v-if="events.length"
      :dataRetrived="dataRetrived"
    />
    <Footer />
  </div>
  <section class="loading" v-else>
    <Navbar />
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <title>Vipon</title>
      <g id="Layer_1-2" data-name="Layer_1">
        <path
          class="f6"
          d="M251.75,324.05,368,206.1a125.33,125.33,0,0,1,7.87,43L251.75,375Z"
        />
        <path
          class="f5"
          d="M251.75,176.32l43-43.62a123.29,123.29,0,0,0-42.63-7.7l-.37.37Z"
        />
        <path
          class="f4"
          d="M251.75,275.66,351,174.94a125.54,125.54,0,0,0-25.28-25.31l-74,75.08Z"
        />
        <path
          class="f3"
          d="M135.5,206.1l116.25,118V375L127.63,249.07A125.33,125.33,0,0,1,135.5,206.1Z"
        />
        <path
          class="f2"
          d="M251.75,125.37v50.95l-43-43.62a123,123,0,0,1,42.63-7.7Z"
        />
        <path
          class="f1"
          d="M251.75,224.71v51L152.48,174.94a125.23,125.23,0,0,1,25.27-25.31Z"
        />
      </g>
    </svg>
    <Footer />
  </section>
</template>

<script>
import { getAllEvents } from "../services/UserServices/UserDetail";
import Observer from "../components/lazy-load/observer";
import Events from "../components/SubComponents/Events";
import Navbar from "@common/Navbar.vue";
import Footer from "@common/BaseFooter.vue";
const queryString = require("query-string");
export default {
  data() {
    return {
      nextPage: null,
      events: [],
      lastPage: "",
      dataRetrived: false,
      navbarText: "رویدادها",
      isLoading : false
    };
  },
  components: {
    Observer,
    Events,
    Navbar,
    Footer,
  },
  methods: {
    async fetch() {
      if (this.nextPage == null && this.lastPage == false) {
        const res = await getAllEvents(`/core/notification/`);
        this.isLoading = false
        this.nextPage = res.data.next;
        this.events = res.data.results;
        if(res.data.next == null){
          this.lastPage = true
          this.dataRetrived = true
        }

      } else if (this.nextPage !== null && this.lastPage == false) {
        const link = queryString.parse(this.nextPage);
        const linkQuery = Object.values(link).toString();

        const res = await getAllEvents(`/core/notification/?page=${linkQuery}`);
        const data = res.data.results;
        this.events = [...this.events, ...data];
        const next = res.data.next;
        if (next == null) {
          this.lastPage = true;
          this.dataRetrived = true;
        }
        this.nextPage = next;

      }
    },
    intersect() {
      this.fetch();
    },
    handleNavigateEvents(event) {
      switch (event.type) {
        case "1":
          this.$router.push({ name: "Buy", params: { id: event.relation_id } });
          break;
        case "2":
          this.$router.push({
            name: "Offer",
            params: { id: event.relation_id, shopId: event.store_id },
          });
          break;
        case "4":
          this.$router.push({
            name: "Bonus",
            params: { id: event.store_id, shopId: event.relation_id },
          });
          break;
        case "6":
          this.$router.push({
            name: "Survey",
            params: { id: event.relation_id, shopId: event.store_id },
          });
          break;
        case "7":
          this.$router.push({ name: "Register", params: { id: event.id } });
          break;
        case "8":
          this.$router.push({ name: "Notice", params: { id: event.id , shopId : event.store_id } });
          break;
        default:
          console.log(`event type = ${event.type}`);
          break;
      }
    },
  },
  mounted() {
    this.isLoading = true
    this.fetch();
  },
};
</script>

<style scoped>
.events {
  background-color: #eee;
  position: relative;
  z-index: 2;
  padding: 60px 10px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}
.event__load-more {
  width: 200px;
  text-align: center;
  background-color: #ddd;
  border-radius: 20px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.empty-events {
  margin: 40px auto;
  font-size: 18px;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
  height: 100vh;
}
</style>