<template>
  <section class="resolve" v-if="!isLoading">
    <Navbar />
    <HeaderComponent :pageData="headerData" />
    <div class="register">
      <p class="register__header">{{ pageData.text }}</p>
      <i class="divider"></i>
      <img
        v-show="pageData.image"
        :src="pageData.image"
        alt=""
        class="register__img"
      />
      <SocialMedia :socials="socials" />
      <StoreNavigator @click="$router.push({ name: 'Store', params: { id: walletId } })" />
    </div>
    <Footer />
  </section>
  <section class="loading" v-if="isLoading"></section>
</template>

<script>
import Navbar from "@common/Navbar.vue";
import HeaderComponent from "@common/HeaderComponent.vue";
import SocialMedia from "@common/SocialMedia.vue";
import StoreNavigator from "@common/StoreNavigator.vue";
import Footer from "@common/BaseFooter.vue";
import { getRegisterData } from "../services/RegisterService/RegisterRequest";
export default {
  name: "Register",
  data() {
    return {
      headerData: {},
      isLoading: false,
      pageData: {},
      socials: [],
      walletId: null,
    };
  },
  props: ["id", "shopId"],
  components: {
    Navbar,
    HeaderComponent,
    SocialMedia,
    StoreNavigator,
    Footer,
  },
  methods: {
    async fetchRegisterData() {
      const res = await getRegisterData(this.id);
      const data = res.data;

      const { store, store_en, store_image, social, wallet_id } = data;
      this.headerData = {
        store_image,
        store_name_en: store_en,
        store_name: store,
      };
      this.pageData = data;
      this.socials = social;
      this.walletId = wallet_id;
    },
  },
  mounted() {
    this.fetchRegisterData();
  },
};
</script>

<style scoped lang="scss">
.register {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 30px 30px 0 0;
  margin-top: -30px;
  background: #eee;
  z-index: 5;
  position: relative;
  padding-bottom: 50px;
  min-height: 61vh;
}
.register__header {
  margin: 25px 0;
  font-size: 17px;
}
.register__img {
  width: 100%;
}
.divider {
  height: 1px;
  width: 80%;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}
</style>