<template>
  <div class="events__item">
    <div class="events__item-info">
      <div v-if="type == 1" class="events__item-logo">
        <svg
          viewBox="0 0 700 512"
          data-v-0f99d34b=""
          class="events__item-logo-img"
        >
          <path
            d="M191.17 113.96l117.82 -0.06c7.11,-0.66 12.57,-6.62 12.57,-13.76 0,-7.13 -5.45,-13.12 -12.56,-13.76l-0.59 -0.05 -69.05 0 0.01 -73.11 -0.06 -0.65c-0.65,-7.11 -6.62,-12.57 -13.76,-12.57 -7.15,0 -13.09,5.47 -13.76,12.58l-0.05 0.56 0 73.19 -20.57 0c-50.44,0 -91.75,41.31 -91.75,91.74 0,50.43 41.31,91.75 91.75,91.75l68.76 0c35.29,0 64.12,28.81 64.12,64.1 0,35.3 -28.82,64.12 -64.12,64.12l-117.19 0 -0.64 0.06c-7.11,0.65 -12.56,6.62 -12.56,13.76 0,7.13 5.45,13.12 12.56,13.76l0.57 0.05 69.07 0 -0.01 73.15 0.06 0.6c0.67,7.11 6.61,12.58 13.76,12.58 7.14,0 13.11,-5.46 13.76,-12.57l0.05 -0.6 0 -73.16 20.57 0c50.44,0 91.75,-41.31 91.75,-91.74 0,-50.44 -41.31,-91.75 -91.75,-91.75l-68.76 0c-35.3,0 -64.11,-28.82 -64.11,-64.11 0,-35.29 28.82,-64.11 64.11,-64.11zm323.11 42.19l-27.12 0 0 86.29 -86.29 0 0 27.12 86.29 0 0 86.29 27.12 0 0 -86.29 86.3 0 0 -27.12 -86.3 0 0 -86.29z"
            data-v-0f99d34b=""
          ></path>
        </svg>
      </div>
      <div v-if="type == 2" class="events__item-logo">
        <svg
          viewBox="0 0 700 512"
          style="width: 38px; height: 38px"
          class="events__item-logo-img"
        >
          <path
            d="M189.67 484.6l148.71 0c7.49,0 13.61,6.11 13.61,13.61 0,7.49 -6.13,13.59 -13.61,13.59l-178.23 0c-15.57,0 -15.51,-13.52 -15.51,-26.79l0 -166.94c0,-7.49 6.11,-13.62 13.61,-13.62 7.49,0 13.6,6.12 13.6,13.62l0 144.87 355.52 -457.66c4.62,-5.95 13.22,-7.04 19.18,-2.41 5.97,4.63 7.07,13.23 2.43,19.2l-359.31 462.53zm21.28 -226.84c-71.19,0 -128.87,-57.7 -128.87,-128.88 0,-71.16 57.7,-128.88 128.87,-128.88 71.18,0 128.9,57.69 128.9,128.88 0,71.21 -57.71,128.88 -128.9,128.88zm278.06 254.24c-71.17,0 -128.86,-57.71 -128.86,-128.88 0,-71.16 57.7,-128.88 128.86,-128.88 71.19,0 128.91,57.69 128.91,128.88 0,71.21 -57.71,128.88 -128.91,128.88zm-278.06 -484.64c-55.98,0 -101.51,45.56 -101.51,101.52 0,55.97 45.53,101.52 101.51,101.52 55.97,0 101.53,-45.54 101.53,-101.52 0,-55.98 -45.56,-101.52 -101.53,-101.52zm278.06 254.24c-55.97,0 -101.49,45.56 -101.49,101.52 0,55.97 45.52,101.52 101.49,101.52 55.98,0 101.54,-45.53 101.54,-101.52 0,-55.98 -45.56,-101.52 -101.54,-101.52z"
          ></path>
        </svg>
      </div>
      <div v-if="type == 4" class="events__item-logo">
        <svg
          viewBox="0 0 700 512"
          style="fill-rule: evenodd; clip-rule: evenodd"
          class="events__item-logo-img"
        >
          <path
            d="M350 0c141.39,0 256,114.61 256,256 0,141.38 -114.61,256 -256,256 -141.39,0 -256,-114.62 -256,-256 0,-141.39 114.61,-256 256,-256zm54.28 167.78l0.63 -0.05c6.98,-0.65 12.33,-6.5 12.33,-13.51 0,-7.01 -5.35,-12.87 -12.33,-13.51l-0.6 -0.06 -40.75 0 0 -43.42 -0.05 -0.62c-0.65,-6.97 -6.5,-12.34 -13.51,-12.34 -7.02,0 -12.87,5.36 -13.51,12.34l-0.06 0.59 0 43.45 -8.89 0c-35.44,0 -64.45,29.01 -64.45,64.46 0,35.44 29.01,64.46 64.45,64.46l44.91 0c20.54,0 37.33,16.78 37.33,37.32 0,20.54 -16.79,37.32 -37.33,37.32l-76.75 0 -0.61 0.06c-6.98,0.64 -12.34,6.5 -12.34,13.51 0,7.01 5.36,12.88 12.34,13.51l0.6 0.06 40.74 0 0 43.45 0.06 0.59c0.64,6.98 6.5,12.33 13.51,12.33 7.01,0 12.86,-5.36 13.51,-12.33l0.05 -0.62 0 -43.42 8.89 0c35.45,0 64.46,-29.02 64.46,-64.46 0,-35.44 -29.01,-64.46 -64.46,-64.46l-44.9 0c-20.54,0 -37.32,-16.78 -37.32,-37.32 0,-20.54 16.78,-37.33 37.32,-37.33l76.73 0zm-54.28 -140.75c126.45,0 228.97,102.51 228.97,228.97 0,126.45 -102.52,228.96 -228.97,228.96 -126.45,0 -228.97,-102.51 -228.97,-228.96 0,-126.46 102.52,-228.97 228.97,-228.97z"
            data-v-0f99d34b=""
          ></path>
        </svg>
      </div>
      <div v-if="type == 6" class="events__item-logo">
        <svg
          viewBox="0 0 880 512"
          data-v-0f99d34b=""
          style="fill-rule: evenodd; clip-rule: evenodd"
          class="events__item-logo-img"
        >
          <path
            d="M514.16 91.98c-23.55,0 -42.82,19.27 -42.82,42.82l0 242.39c0,23.55 19.27,42.83 42.82,42.83l242.39 0c23.55,0 42.82,-19.28 42.82,-42.83l0 -242.39c0,-23.55 -19.27,-42.82 -42.82,-42.82l-242.39 0zm0 27.2c-8.54,0 -15.62,7.09 -15.62,15.62l0 242.39c0,8.54 7.08,15.63 15.62,15.63l242.39 0c8.54,0 15.62,-7.09 15.62,-15.63l0 -242.39c0,-8.53 -7.08,-15.62 -15.62,-15.62l-242.39 0zm-390.71 0c-8.54,0 -15.62,7.09 -15.62,15.62l0 242.39c0,8.54 7.08,15.63 15.62,15.63l242.39 0c8.54,0 15.62,-7.09 15.62,-15.63l0 -242.39c0,-8.53 -7.08,-15.62 -15.62,-15.62l-242.39 0zm0 -27.2c-23.55,0 -42.82,19.27 -42.82,42.82l0 242.39c0,23.55 19.27,42.83 42.82,42.83l242.39 0c23.55,0 42.82,-19.28 42.82,-42.83l0 -242.39c0,-23.55 -19.27,-42.82 -42.82,-42.82l-242.39 0z"
            data-v-0f99d34b=""
          ></path>
          <path
            d="M546.99 223.95l88.25 88.24 88.24 -88.24 0.08 -0.09 5.15 -5.14 47.8 -47.8 7.84 -7.85 39.22 -39.21 30.93 -30.93c5.29,-5.29 13.93,-5.29 19.22,0l0 0c5.28,5.29 5.28,13.93 0,19.22l-30.93 30.92 -39.22 39.22 -7.84 7.85 -47.8 47.8 -39.22 39.22 -0.09 0.08 -59.26 59.27c-4.17,4.17 -8.27,5.62 -13.61,5.72 -3.11,0.06 -10.21,-1.3 -13.11,-4.2l-94.86 -94.86c-5.29,-5.29 -5.29,-13.94 0,-19.22l0 0c5.28,-5.29 13.93,-5.29 19.21,0z"
            data-v-0f99d34b=""
          ></path>
        </svg>
      </div>
      <div v-if="type == 7" class="events__item-logo">
        <svg viewBox="0 0 800 512" class="events__item-logo-img">
          <path
            d="M652.28 209.19l0 288.63c-0.17,7.92 -6.67,14.18 -14.57,14.18l-476.07 0c-0.1,0 -0.21,0 -0.32,-0.01 -7.64,-0.19 -13.76,-6.57 -13.56,-14.21l0 -288.59 -2.02 0c-21.66,0 -39.11,-18 -39.11,-39.54l0 -52.03c-0.02,-0.12 -0.04,-0.24 -0.04,-0.37 0.01,-0.4 -0.01,-0.79 0.03,-1.19 0.01,-0.19 0.03,-0.37 0.06,-0.55 0.03,-0.23 0.06,-0.46 0.1,-0.69 0.04,-0.2 0.07,-0.4 0.12,-0.58 0.04,-0.19 0.08,-0.36 0.13,-0.54 0.14,-0.52 0.3,-1.02 0.48,-1.49 0.02,-0.05 0.03,-0.09 0.05,-0.15l0.03 -0.08c0.11,-0.25 0.22,-0.5 0.34,-0.75l0.05 -0.11c0.04,-0.06 0.06,-0.12 0.09,-0.18 0.13,-0.26 0.27,-0.51 0.43,-0.78l0.03 -0.05c0.03,-0.06 0.07,-0.11 0.1,-0.17 0.17,-0.28 0.35,-0.54 0.53,-0.81l64.49 -92.39c7.33,-10.49 19.33,-16.74 32.13,-16.74l398.33 0c13.85,0 26.69,7.32 33.73,19.24l53.66 90.83c0.03,0.05 0.04,0.08 0.06,0.11 0.6,1.04 1.06,2.14 1.39,3.3 0.02,0.09 0.04,0.19 0.06,0.26 0.12,0.49 0.22,0.99 0.28,1.49 0.02,0.1 0.02,0.2 0.03,0.28l0.01 0.11c0.06,0.56 0.09,1.13 0.07,1.68l0 52.35c0,21.54 -17.44,39.54 -39.1,39.54l-2.02 0zm-504.12 -105.76l506.09 0 -40.96 -69.51c-1.92,-3.26 -5.39,-5.35 -9.17,-5.41l-398.3 0c-3.53,0.06 -6.78,1.84 -8.79,4.73l-48.87 70.19zm307.39 380.06l0 -165.89 -111.06 0 0 165.89 111.06 0zm168.22 0l0 -274.3 -447.5 0 0 274.3 139.72 0 -0.01 -180.09c-0.08,-7.82 6.19,-14.21 14.02,-14.3l139.36 0c8,0 14.47,6.39 14.7,14.38l0 180.01 139.71 0zm-342.13 -189.41c7.87,0 14.25,6.38 14.25,14.26l0 73.52c0,7.87 -6.38,14.25 -14.25,14.25l-78.5 0c-7.87,0 -14.26,-6.38 -14.26,-14.25l0 -73.52c0,-7.88 6.39,-14.26 14.26,-14.26l78.5 0zm314.02 0c7.87,0 14.25,6.38 14.25,14.26l0 73.52c0,7.87 -6.38,14.25 -14.25,14.25l-78.5 0c-7.87,0 -14.26,-6.38 -14.26,-14.25l0 -73.52c0,-7.88 6.38,-14.26 14.26,-14.26l78.5 0zm-328.27 73.52l0 -45.01 -50 0 0 45.01 50 0zm314.02 0l0 -45.01 -50 0 0 45.01 50 0zm83.49 -235.66l-529.75 0 0 37.71c0,5.93 4.63,10.88 10.57,11.04l508.52 -0.01c5.98,-0.16 10.66,-5.07 10.66,-11.03l0 -37.71z"
            data-v-0f99d34b=""
          ></path>
        </svg>
      </div>
      <div v-if="type == 8" class="events__item-logo">
        <svg
          viewBox="0 0 600 512"
          class="events__item-logo-img"
        >
          <path
            d="M575.76 361.11l-0.01 -307.91c0,-29.17 -23.57,-52.94 -52.67,-53.2l-445.61 0c-29.36,0 -53.22,23.84 -53.22,53.21l0 307.89c0,29.33 23.82,53.31 53.21,53.21l75.57 -0.28 0 83.65c0,5.3 3.11,10.11 7.92,12.3 4.76,2.17 10.46,1.41 14.36,-1.99l105.82 -94.01 241.4 0.34c29.37,0.04 53.23,-23.85 53.23,-53.21zm-395.66 106.86l0 -67.38c0,-7.4 -6.01,-13.55 -13.45,-13.53l-89.2 0.21c-6.99,0.01 -13.52,-2.75 -18.45,-7.69 -4.94,-4.94 -7.69,-11.48 -7.69,-18.47l0 -307.9c0,-6.99 2.75,-13.53 7.69,-18.47 4.94,-4.93 11.48,-7.69 18.47,-7.69l445.06 0c6.99,0 13.53,2.76 18.47,7.7 4.93,4.93 7.68,11.47 7.68,18.46l0.01 307.9c0,6.99 -2.75,13.53 -7.69,18.47 -4.94,4.93 -11.49,7.69 -18.47,7.68l-246.49 -0.33c-3.16,0 -6.39,1.17 -8.72,3.2l-87.22 77.84z"
          ></path>
          <path
            d="M132.49 229.61l276.62 0c6.72,0 12.22,6.08 12.22,13.52l0 0c0,7.44 -5.5,13.53 -12.22,13.53l-276.62 0c-6.72,0 -12.21,-6.09 -12.21,-13.53l0 0c0,-7.44 5.49,-13.52 12.21,-13.52zm0 -76.08l134.87 0c6.72,0 12.21,6.09 12.21,13.53l0 0c0,7.43 -5.5,13.52 -12.21,13.52l-134.87 0c-6.72,0 -12.21,-6.09 -12.21,-13.52l0 0c0,-7.44 5.49,-13.53 12.21,-13.53z"
          ></path>
        </svg>
      </div>
      <div class="events__item-detail">
        <p v-if="type == 1" class="events__item-detail--title-color">خرید</p>
        <p v-if="type == 2" class="events__item-detail--title-color">
          آفر (تخفیف)
        </p> 
        <p v-if="type == 4" class="events__item-detail--title-color">
          بونوس(تخفیف)
        </p>
        <p v-if="type == 6" class="events__item-detail--title-color">نظرسنجی</p>
        <p v-if="type == 7" class="events__item-detail--title-color">عضویت</p>
        <p v-if="type == 8" class="events__item-detail--title-color">پیام</p>
        <p class="events__item-detail--text-color">{{ store }}</p>
      </div>
    </div>
    <div class="events__item-time eng-num">
      <p>{{ jalaliEvent(time).date }}</p>
      <p>{{ jalaliEvent(time).hour }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Events",
  data() {
    return {
      events: [],
    };
  },
  methods: {
    jalaliEvent(time) {
      const date = new Date(time).toLocaleDateString("fa-IR")
      let hourTime = new Date(time).toLocaleTimeString("fa-IR")
      const hour = hourTime.slice(0, -3);
      return { date, hour };
    },
  },
  props: ["type", "id", "store", "time", "relation"],
};
</script>

<style scoped lang="scss">
.events__item {
  background-color: #fff;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  border-radius: 15px;
  padding: 0 15px 0 22px;
  height: 90px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.events__item-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.events__item-time {
  margin-right: 10px;
  flex-direction: column;
  display: flex;
  text-align: left;
  line-height: 15px;
  color: #aaa;
}
.events__item-logo {
  width: 65px;
  height: 65px;
  display: flex;
  background-color: #121212;
  align-items: center;
  border-radius: 100%;
  justify-content: center;
  justify-self: center;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.07);
  .events__item-logo-img {
    fill: #c69d4e;
    width: 41px;
    height: 41px;
  }
}
.events__item-detail {
  margin-right: 10px;
}
.events__item-detail--title-color {
  color: #333;
}
.events__item-detail--text-color {
  color: #aaa;
  font-size: 14px;
}
</style>