import { createStore } from "vuex";
import router from "../router/index";

const store = createStore({
    state: {
        refreshToken: localStorage.getItem("userRefreshToken"),
        accessToken: localStorage.getItem("userAccessToken"),
        isAuthenticated: localStorage.getItem("userCompleted"),
    },
    mutations: {
        SET_AUTH: (state, completed) => {
            localStorage.setItem("userCompleted", completed);
            state.isAuthenticated = completed;
        },
        SET_REFRESH_TOKEN: (state, refresh) => {
            state.refreshToken = refresh;
            localStorage.setItem("userRefreshToken", refresh);
        },
        SET_ACCESS_TOKEN: (state, access) => {
            state.accessToken = access;
            localStorage.setItem("userAccessToken", access);
        },
        // delete all auth and user information from the state
        CLEAR_USER_DATA: (state) => {
            state.refreshToken = "";
            state.accessToken = "";
            state.isAuthenticated = false;
            localStorage.removeItem("userAccessToken");
            localStorage.removeItem("userRefreshToken");
            localStorage.removeItem("userCompleted");
            router.push({ name: "Login" });
        },
    },
    actions: {
        logIn: async({ commit }, payLoad) => {
            commit("SET_REFRESH_TOKEN", payLoad.data.refresh);
            commit("SET_ACCESS_TOKEN", payLoad.data.access);
            commit("SET_AUTH", payLoad.data.completed);
            // redirect to the home page
            if (payLoad.data.completed == true) {
                router.push({ name: "Home" });
            } else {
                router.push({ name: "SignUp" })
            }
        },
        refreshToken: async({ commit }, data) => {
            commit("SET_ACCESS_TOKEN", data);
        },
        clearUser: ({ commit }) => {
            commit("CLEAR_USER_DATA");
        },
        signedUp: ({ commit }, data) => {
            commit('SET_AUTH', data)
        }
    },
    getters: {
        isAuthenticated: (state) => state.isAuthenticated,
        accessToken: (state) => state.accessToken,
        refreshToken: (state) => state.refreshToken,
    },
});

export default store;