<template>
  <div class="footer">
    <div class="footer-item" @click="footerNavigator('Home')">
      <svg viewBox="0 0 512 512" class="footer__img">
        <path
          d="M256 10.01l-256 217.71 0 293.21 203.97 0 0 -186.68c0,-28.61 23.41,-52.03 52.03,-52.03 28.62,0 52.03,23.42 52.03,52.03l0 186.68 203.97 0 0 -293.21 -256 -217.71zm0.29 33.19l229.48 194.08 0 257.42 -151.52 0 0 -160.45c0,-43.09 -35.16,-78.25 -78.25,-78.25 -43.09,0 -78.26,35.16 -78.26,78.25l0 160.45 -151.52 0 0 -257.41 230.07 -194.09z"
        ></path>
      </svg>
    </div>
    <i class="divider"></i>
    <div class="footer-item" @click="footerNavigator('StoreShop')">
      <svg viewBox="0 0 700 512" class="footer__img footer__img--size">
        <path
          d="M57.73 111.01l-0.04 0.09c-0.11,0.23 -0.22,0.46 -0.31,0.68l-0.02 0.04c-0.02,0.07 -0.04,0.13 -0.06,0.18 -0.17,0.46 -0.32,0.93 -0.45,1.4 -0.04,0.17 -0.08,0.33 -0.13,0.55 -0.05,0.17 -0.07,0.35 -0.1,0.49 -0.04,0.21 -0.07,0.43 -0.1,0.69 -0.02,0.14 -0.04,0.29 -0.05,0.48 -0.04,0.37 -0.02,0.75 -0.03,1.09 0,0.07 0.02,0.15 0.03,0.21l0.02 0.08 0 52.32c0,21.07 17.07,38.69 38.26,38.69l3.03 0 0 290.75c-0.19,7.12 5.53,13.06 12.69,13.24 0.08,0.01 0.16,0.01 0.24,0.01l477.93 0c7.39,0 13.47,-5.85 13.63,-13.24l0 -290.76 3.03 0c21.19,0 38.25,-17.62 38.25,-38.69l0 -52.59c0.02,-0.51 -0.01,-1.03 -0.07,-1.57l-0.01 -0.08c0,-0.06 0,-0.14 -0.02,-0.27 -0.06,-0.47 -0.15,-0.93 -0.26,-1.34 -0.03,-0.11 -0.04,-0.2 -0.06,-0.28 -0.31,-1.08 -0.72,-2.08 -1.27,-3.03 -0.04,-0.05 -0.05,-0.09 -0.08,-0.14l-53.87 -91.19c-6.89,-11.66 -19.45,-18.82 -33,-18.82l-399.89 0c-12.52,0 -24.27,6.11 -31.43,16.37l-64.73 92.74c-0.18,0.26 -0.34,0.49 -0.48,0.72 -0.04,0.07 -0.07,0.11 -0.12,0.21l-0.03 0.05c-0.14,0.24 -0.28,0.48 -0.4,0.72 -0.04,0.08 -0.07,0.14 -0.1,0.2zm37.52 -7.18l50.16 -72.04c2.21,-3.16 5.77,-5.11 9.65,-5.18l399.88 0c4.15,0.07 7.95,2.36 10.05,5.93l42.01 71.29 -511.75 0zm311.52 381.55l-113.5 0 0 -168.54 113.5 0 0 168.54zm168.88 0l-142.26 0 0 -181.69c-0.22,-7.47 -6.26,-13.46 -13.76,-13.46l-139.89 0c-7.3,0.08 -13.16,6.04 -13.08,13.35l0.01 181.8 -142.28 0 0 -277.38 451.26 0 0 277.38zm41.29 -354.93l0 38.86c0,6.53 -5.13,11.9 -11.7,12.08l-510.54 0.01c-6.52,-0.18 -11.59,-5.61 -11.59,-12.09l0 -38.86 533.83 0zm-385.76 164.78l-78.81 0c-7.35,0 -13.31,5.96 -13.31,13.31l0 73.81c0,7.35 5.97,13.3 13.31,13.3l78.81 0c7.35,0 13.3,-5.95 13.3,-13.3l0 -73.81c0,-7.35 -5.95,-13.31 -13.3,-13.31zm-13.31 73.81l-52.2 0 0 -47.19 52.2 0 0 47.19zm328.56 -73.81l-78.81 0c-7.36,0 -13.31,5.96 -13.31,13.31l0 73.81c0,7.35 5.96,13.3 13.31,13.3l78.81 0c7.35,0 13.3,-5.95 13.3,-13.3l0 -73.81c0,-7.35 -5.95,-13.31 -13.3,-13.31zm-13.3 73.81l-52.21 0 0 -47.19 52.21 0 0 47.19z"
        ></path>
      </svg>
    </div>
    <i class="divider"></i>
    <div class="footer-item" @click="footerNavigator('Tickets')">
      <svg viewBox="0 0 600 512" class="footer__img">
        <path
          d="M575.76 361.11l-0.01 -307.91c0,-29.17 -23.57,-52.94 -52.67,-53.2l-445.61 0c-29.36,0 -53.22,23.84 -53.22,53.21l0 307.89c0,29.33 23.82,53.31 53.21,53.21l75.57 -0.28 0 83.65c0,5.3 3.11,10.11 7.92,12.3 4.76,2.17 10.46,1.41 14.36,-1.99l105.82 -94.01 241.4 0.34c29.37,0.04 53.23,-23.85 53.23,-53.21zm-395.66 106.86l0 -67.38c0,-7.4 -6.01,-13.55 -13.45,-13.53l-89.2 0.21c-6.99,0.01 -13.52,-2.75 -18.45,-7.69 -4.94,-4.94 -7.69,-11.48 -7.69,-18.47l0 -307.9c0,-6.99 2.75,-13.53 7.69,-18.47 4.94,-4.93 11.48,-7.69 18.47,-7.69l445.06 0c6.99,0 13.53,2.76 18.47,7.7 4.93,4.93 7.68,11.47 7.68,18.46l0.01 307.9c0,6.99 -2.75,13.53 -7.69,18.47 -4.94,4.93 -11.49,7.69 -18.47,7.68l-246.49 -0.33c-3.16,0 -6.39,1.17 -8.72,3.2l-87.22 77.84z"
        ></path>
        <path
          d="M132.49 229.61l276.62 0c6.72,0 12.22,6.08 12.22,13.52l0 0c0,7.44 -5.5,13.53 -12.22,13.53l-276.62 0c-6.72,0 -12.21,-6.09 -12.21,-13.53l0 0c0,-7.44 5.49,-13.52 12.21,-13.52zm0 -76.08l134.87 0c6.72,0 12.21,6.09 12.21,13.53l0 0c0,7.43 -5.5,13.52 -12.21,13.52l-134.87 0c-6.72,0 -12.21,-6.09 -12.21,-13.52l0 0c0,-7.44 5.49,-13.53 12.21,-13.53z"
        ></path>
      </svg>
    </div>

    <i class="divider"></i>
    <div class="footer-item" @click="footerNavigator('')">
      <svg viewBox="0 0 512 512" class="footer__img">
        <g id="Layer_x0020_1">
          <metadata id="CorelCorpID_0Corel-Layer"></metadata>
          <path
            d="M409.2 262.86l0 -47.86c0,-67.44 -44.37,-127.05 -108.72,-146.61l-1.28 -0.39 0 -23c0,-23.82 -19.38,-43.2 -43.2,-43.2 -23.82,0 -43.2,19.38 -43.2,43.2l0 23 -1.28 0.39c-64.35,19.56 -108.72,79.17 -108.72,146.61l0 47.86c0,61.79 -23.56,120.41 -66.33,165 -3.64,3.81 -4.68,9.48 -2.61,14.32 2.07,4.87 6.85,8.02 12.14,8.02l137.98 0 0.29 1.44c6.93,33.98 37,58.56 71.73,58.56 34.73,0 64.8,-24.58 71.73,-58.56l0.29 -1.44 137.98 0c5.29,0 10.07,-3.15 12.14,-8.02 2.07,-4.84 1.03,-10.52 -2.61,-14.32 -42.77,-44.6 -66.33,-103.21 -66.33,-165zm-170 -217.86c0,-9.26 7.54,-16.8 16.8,-16.8 9.26,0 16.8,7.54 16.8,16.8l0 17.71 -1.97 -0.19c-4.93,-0.48 -9.88,-0.72 -14.83,-0.72 -4.95,0 -9.9,0.24 -14.83,0.72l-1.97 0.19 0 -17.71zm16.8 438.8c-19.83,0 -37.51,-12.56 -44.11,-31.2l-0.86 -2.4 89.94 0 -0.85 2.4c-6.61,18.64 -24.29,31.2 -44.12,31.2zm-179.11 -62.88c34.16,-45.8 52.31,-100.94 52.31,-158.06l0 -47.86c0,-69.92 56.88,-126.8 126.8,-126.8 69.92,0 126.8,56.88 126.8,126.8l0 47.86c0,57.12 18.15,112.26 52.31,158.06l2.15 2.88 -362.52 0 2.15 -2.88z"
          ></path>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  methods: {
    footerNavigator(navTo) {
      switch (navTo) {
        case "Home":
          this.$router.push({ name: "Home" });
          break;
        case "StoreShop":
          this.$router.push({ name: "StoreShop" });
          break;
        case "Tickets":
          this.$router.push({ name: "Tickets" });
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.footer {
  display: grid;
  grid-template-columns: 1fr 1px 1fr 1px 1fr 1px 1fr;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 200;
  background-color: #fff;
  height: 50px;
  width: 100%;
}
.footer__img {
  fill: #131313;
  height: 25px;
  width: 25px;
}
.footer__img--size{
  height: 35px;
  width: 35px;
}
.divider {
  height: 60%;
  background-color: #ddd;
}
.footer-item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>