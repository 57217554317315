<template>
  <section class="resolved" v-if="!isLoading">
    <Navbar :text="navbarText" />
    <HeaderComponent :pageData="headerData" />
    <div class="store-body">
      <section class="store-info">
        <div class="store-info-item">
          <p class="store-info__title">عضویت شما</p>
          <span class="eng-num store-info__date">{{ buyTime(pageData) }}</span>
          <p>روز</p>
        </div>
        <i class="store-info__divider"></i>
        <div class="store-info-item">
          <p class="store-info__title">تعداد خرید</p>
          <span class="eng-num store-info__date">{{ pageData.buy_count }}</span>
          <p>مرتبه</p>
        </div>
        <i class="store-info__divider"></i>
        <div class="store-info-item">
          <p class="store-info__title">آخرین خرید</p>
          <span class="eng-num store-info__date">{{ buyTime(pageData) }}</span>
          <p>روز پیش</p>
        </div>
      </section>
      <section class="amount">
        <p class="amount__title">اعتبار شما</p>
        <p class="amount__value eng-num">
          {{ pageData.amount?.toLocaleString() }}
        </p>
        <button class="amount__btn" @click="payWithCredit">
          <span class="amount__btn-text">پرداخت از اعتبار</span>
        </button>
      </section>
      <Grades
        :groupData="groupData"
        :groups="groups"
        :pageData="pageData"
        :nextLvl="nextStep"
      />
      <SocialMedia :socials="socials" />
      <section class="branches">
        <p class="branch__title">شعبه ها</p>
        <Branch
          v-for="(branch, index) in branches"
          :key="index"
          :image="branch.image"
          :title="branch.title"
          :address="branch.address"
          @click="
            $router.push({ name: 'Branch', params: { id: id, idx: branch.id } })
          "
        />
      </section>
    </div>
    <div class="modal">
      <div class="pay" :class="[payModal && 'active']">
        <div class="pay__header">
          <p>پرداخت از اعتبار</p>
        </div>
        <div class="pay__content">
          <div class="pay__credit-value">
            <p class="pay__credit-value-amount">اعتبار شما :</p>
            <p class="pay__credit-value-number">{{ cashierBalance }}</p>
            <p class="pay__credit-value-symbol">ریال</p>
          </div>
          <div class="pay__feedback">
            <input
              type="number"
              placeholder="مبلغ پرداخت"
              class="pay__feedback-input"
              v-model="payWithCreditInput"
            />
            <div class="pay__feedback-text">ریال</div>
          </div>
          <div class="pay__btns">
            <button class="pay__btn pay__btn--choose" @click="acceptorChoose">
              <p class="pay__text">
                {{
                  cashierName.name === "" || cashierName.name === undefined
                    ? "انتخاب پذیرنده"
                    : `پذیرنده : ${cashierName.name}`
                }}
              </p>
            </button>
            <button class="pay__btn pay__btn--pay" @click="postPayDetail">
              <p class="pay__text">پرداخت</p>
            </button>
          </div>
        </div>
      </div>
      <div class="acceptor-choose" :class="[acceptorModal && 'active']">
        <div class="acceptor-choose__header">پذیرنده ها</div>
        <div class="acceptor-choose__content">
          <div
            class="cashier"
            v-for="(cashier, index) in cashiers"
            :key="index"
            @click="chooseCashier(cashier)"
          >
            {{ cashier.name }}
          </div>
        </div>
      </div>
      <div class="backdrop" v-if="payModal" @click.self="backDrop('pay')"></div>
      <div
        class="backdrop"
        v-if="acceptorModal"
        @click.self="backDrop('choose')"
      ></div>
    </div>
    <Footer />
  </section>
  <section class="loading" v-else>
    <Navbar />
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <title>Vipon</title>
      <g id="Layer_1-2" data-name="Layer_1">
        <path
          class="f6"
          d="M251.75,324.05,368,206.1a125.33,125.33,0,0,1,7.87,43L251.75,375Z"
        />
        <path
          class="f5"
          d="M251.75,176.32l43-43.62a123.29,123.29,0,0,0-42.63-7.7l-.37.37Z"
        />
        <path
          class="f4"
          d="M251.75,275.66,351,174.94a125.54,125.54,0,0,0-25.28-25.31l-74,75.08Z"
        />
        <path
          class="f3"
          d="M135.5,206.1l116.25,118V375L127.63,249.07A125.33,125.33,0,0,1,135.5,206.1Z"
        />
        <path
          class="f2"
          d="M251.75,125.37v50.95l-43-43.62a123,123,0,0,1,42.63-7.7Z"
        />
        <path
          class="f1"
          d="M251.75,224.71v51L152.48,174.94a125.23,125.23,0,0,1,25.27-25.31Z"
        />
      </g>
    </svg>
    <Footer />
  </section>
</template>

<script>
import { getStoreData } from "../services/StoreServices/StoreRequest";
import { getCashiersData } from "../services/StoreServices/StoreRequest";
import { postPayWithCreditDetails } from "../services/StoreServices/StoreRequest";
import { getBranchData } from "../services/BranchServices/BranchRequest";
import HeaderComponent from "@common/HeaderComponent.vue";
import Navbar from "@common/Navbar.vue";
import Footer from "@common/BaseFooter.vue";
import Grades from "@common/Grades.vue";
import SocialMedia from "@common/SocialMedia.vue";
import Branch from "@common/BranchBox.vue";

export default {
  name: "Store",
  data() {
    return {
      navbarText: "فروشگاه",
      headerData: {},
      pageData: {},
      groups: [],
      groupData: {},
      socials: [],
      branches: [],
      payModal: false,
      acceptorModal: false,
      isLoading: false,
      idx: null,
      cashiers: [],
      cashierBalance: null,
      cashierName: {},
      payWithCreditInput: "",
      nextStep: {},
      buySum: null,
    };
  },
  props: ["id", "query"],
  components: {
    Branch,
    HeaderComponent,
    Navbar,
    Grades,
    SocialMedia,
    Footer,
  },
  methods: {
    async fetchStoreData() {
      // call method to fetch data
      const res = await getStoreData(this.id);
      this.isLoading = false;
      // restore data in variable
      const data = res.data;
      const { groups, group, social, buy_sum , image , store , store_en , store_id } = data;
      // header Data
      this.headerData = {
        store_image: image,
        store_name: store,
        store_name_en: store_en,
      };
      // all of data will be store in pageDate object
      this.pageData = data;
      // all the grades => bronze - silver etc ...
      this.groups = groups;
      // current grade that user in it
      this.groupData = group;
      // social media info
      this.socials = social;
      // store id for passing it to child components
      this.idx = store_id;
      // all the buy cost of user 
      this.buySum = buy_sum;
      // this method will calculate user's grade and pass next grade to child component => grades component
      this.nextGrade();
    },
    async fetchBranchData() {
      const res = await getBranchData(this.id);
      const data = res.data;
      this.branches = data.results;
    },
    buyTime(data) {
      // convert to miliseconds
      const miliSecond = 1000 * 60 * 60 * 24;
      // diff time
      const diffDays = (now, time) => {
        // we store 3 params in utc - year : number , month : number , days : number
        const time1 = Date.UTC(
          now.getFullYear(),
          now.getMonth(),
          now.getDate()
        );
        const time2 = Date.UTC(
          time.getFullYear(),
          time.getMonth(),
          time.getDate()
        );
        return Math.floor((time1 - time2) / miliSecond);
      };
      const now = new Date();
      // convert time of first bought from user to miliseconds
      const time = new Date(data.first_buy);
      // diffrence will call above function and pass the current time and firstBuy/lastBuy timestamp
      const difference = diffDays(now, time);
      // every time this method called - this method will call and return a time to it
      return difference;
    },
    async payWithCredit() {
      this.payModal = true;
      const res = await getCashiersData(this.idx);
      const data = res.data;
      this.cashierBalance = data.balance;
      this.cashiers = data.cashiers;
    },
    acceptorChoose() {
      this.payModal = false;
      this.acceptorModal = true;
    },
    backDrop(status) {
      switch (status) {
        case "pay":
          this.payModal = false;
          break;
        case "choose":
          this.acceptorModal = false;
          this.payModal = true;
        default:
          break;
      }
    },
    chooseCashier(cashier) {
      this.cashierName.name = cashier.name;
      this.cashierName.id = cashier.id;
      this.acceptorModal = false;
      this.payModal = true;
    },
    postPayDetail() {
      const bodyFormData = new FormData();
      bodyFormData.append("cashier", this.cashierName.id);
      bodyFormData.append("price", this.payWithCreditInput);

      postPayWithCreditDetails(this.idx, bodyFormData, {
        "Content-Type": "multipart/form-data",
      }).then((Response) => {
        if (Response.status == 200) {
          this.payModal = false;
          this.payWithCreditInput = "";
          this.cashierName.name = "";
          this.fetchStoreData();
        }
      });
    },
    nextGrade() {
      // if this.groupData.buy_sum = 6000000 next lvl will be silver
      const lvl = this.groups.filter((i) => i.min_buy > this.buySum);
      // select lowest min_buy between grades array
      let min = Math.min(...lvl.map((i) => i.min_buy));
      // filter bigger than our lowest (next lvl)
      const minimum = lvl.filter((i) => i.min_buy <= min);
      this.nextStep = minimum[0];
    },
  },
  mounted() {
    this.isLoading = true;
    this.fetchStoreData();
    this.fetchBranchData();
  },
};
</script>

<style scoped lang="scss">
.store-body {
  position: relative;
  z-index: 2;
  width: 100%;
  border-radius: 0;
  background: linear-gradient(180deg, #fff 100px, #eee 150px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border-radius: 30px 30px 0 0;
  margin-top: -30px;
  padding-top: 20px;
}
.store-info {
  width: 92%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 0 30px;
  border-radius: 15px;
  overflow: hidden;
  height: 105px;
}
.store-info__date {
  font-size: 20px;
  margin: 5px 0;
}
.store-info-item {
  width: calc(33% - 1px);
  height: 105px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.store-info__divider {
  height: 80%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
.amount {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.amount__title {
  color: #777;
  font-size: 1rem;
  margin: 5px 0 10px;
}
.amount__value {
  color: #111;
  font-size: 1.25rem;
  margin-bottom: 20px;
  position: relative;
  &::after {
    position: absolute;
    content: "R";
    color: #777;
    right: -8px;
    font-size: 10px;
    top: 0;
  }
}
.amount__btn {
  width: 120px;
  height: 120px;
  border-radius: 100%;
  margin-bottom: 30px;
  background-color: #fff;
  cursor: pointer;
}
.amount__btn-text {
  color: #c69d4e;
  font-size: 16px;
  line-height: 26px;
  -webkit-animation: blinking 1.3s infinite;
  animation: blinking 1.3s infinite;
}
@keyframes blinking {
  0% {
    color: #c69d4e;
  }
  33% {
    color: #fff;
  }
  66% {
    color: #c69d4e;
  }
}
.branches {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.branch__title {
  font-size: 17px;
  margin: 10px 0;
}
.modal {
  width: 100%;
  position: relative;
}
.pay {
  position: fixed;
  overflow: hidden;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 50%;
  width: 96%;
  z-index: 300;
  border-radius: 20px;
  transform: translateY(-200%);
  transition: all 0.4s ease;
}
.pay.active {
  transform: translateY(0%);
}
.pay__header {
  position: relative;
  height: 60px;
  width: 100%;
  background-color: #ddd;
  color: #000;
  text-align: center;
  line-height: 60px;
}
.pay__content {
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  border-radius: 0 0 20px 20px;
  padding: 10px 20px;
}
.backdrop {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 200;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}
.pay__credit-value {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 5px 0 10px;
}
.pay__credit-value-amount {
  font-size: 17px;
}
.pay__credit-value-number {
  margin: 0 10px;
  font-size: 17px;
}
.pay__credit-value-symbol {
  font-size: 12px;
}
.pay__feedback {
  width: 90%;
  height: 40px;
  position: relative;
}
.pay__feedback::after {
  content: "";
  position: absolute;
  left: 0px;
  bottom: 0;
  height: 2px;
  width: 100%;
  background-color: #c69d4e;
  margin: auto;
  transition: all 0.3s ease;
  z-index: 100;
}
.pay__feedback-input {
  width: 100%;
  padding: 0 0 0 50px;
  height: 100%;
}
.pay__feedback-text {
  position: absolute;
  left: 5px;
  top: 10px;
  color: #333;
}
.pay__feedback-input::-webkit-inner-spin-button,
.pay__feedback-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
// pay btns
.pay__btns {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.pay__btn {
  width: 90%;
  margin: 15px auto;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  text-align: center;
  height: 60px;
  line-height: 58px;
  .pay__text {
    text-align: center;
    width: 100%;
  }
}
.pay__btn--pay {
  background-color: #000;
  color: #fff;
}
.acceptor-choose {
  position: fixed;
  overflow: hidden;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 30%;
  width: 96%;
  z-index: 300;
  border-radius: 20px;
  transform: translateY(-300%);
  transition: all 0.4s ease;
  background-color: #fff;
}
.acceptor-choose.active {
  transform: translateY(0%);
}
.acceptor-choose__header {
  position: relative;
  height: 60px;
  width: 100%;
  background-color: #ddd;
  color: #000;
  text-align: center;
  line-height: 60px;
}
.acceptor-choose__content {
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  border-radius: 0 0 20px 20px;
}
.cashier {
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin: 10px 0;
  width: 96%;
  text-align: center;
  cursor: pointer;
  color: #c69d4e;
  background-color: #ddd;
}
</style>